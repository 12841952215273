<template>
  <v-container class="fill-height" fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" class="d-flex justify-space-around">
        <v-btn fab right @click="pdf">
          <v-icon>picture_as_pdf</v-icon>
        </v-btn>
        <v-btn fab right @click="saveExcel">
          <v-icon>save</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="fill-height">
      <v-col cols="12">
        <div id="luckysheet" class="fill-height" style="padding-bottom: 80px"></div>
        <!--          style="margin:0px;padding:0px;position:absolute;width:100%;left: 0px;top: 30px;bottom:0px;"-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BACKEND_URL } from '@/za_conf';
import axios from 'axios';
import { formatOfferId } from '@/store';

// axios
//   .get(`${BACKEND_URL}/spreadsheet`)
//   .then(response => {
//     window.luckysheet.create({
//       container: 'luckysheet', //luckysheet is the container id
//       showinfobar: false,
//       data: response.data.sheets
//     });
//
//   })
//   .catch(() => {});

export default {
  name: 'LuckySheet',
  data() {
    return {
      test: null
    };
  },
  computed: {
    excelUUID() {
      return this.$store.getters.excelUUID;
    },
    excelVersion() {
      return this.$store.getters.excelVersion;
    }
  },
  methods: {
    pdf() {
      let content = luckysheet.getAllSheets();
      axios.post(`${BACKEND_URL}/spreadsheet_update`, { uuid: this.excelUUID, content: content }).then(resp => {
        axios
          .post(
            `${BACKEND_URL}/pdf_excel`,
            { excel_uuid: this.excelUUID, offer_id: this.$store.getters.currentOfferID },
            { responseType: 'blob' }
          )
          .then(response => {
            let version = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${formatOfferId(this.$store.getters.currentOfferID)}-V${version}.pdf`);
            document.body.appendChild(link);
            link.click();
          })
          .catch(error => {});
      });
    },
    saveExcel() {
      // eslint-disable-next-line no-undef
      let content = luckysheet.getAllSheets();
      axios.post(`${BACKEND_URL}/spreadsheet_update`, { uuid: this.excelUUID, content: content }).then(resp => {
        // eslint-disable-next-line no-undef
      });
    }
  },
  mounted() {
    let excelPayload = this.$store.getters.excelPayload;
    axios
      .post(`${BACKEND_URL}/spreadsheet`, {
        uuid: this.excelUUID
      })
      .then(resp => {
        // eslint-disable-next-line no-undef
        luckysheet.create({
          container: 'luckysheet', //luckysheet is the container id
          showinfobar: false,
          gridKey: 'zeroadmin',
          showtoolbar: false,
          showsheetbar: true,
          data: resp.data,
          hook: {
            workbookCreateAfter: function() {
              luckysheet.setSheetActive(0);
              if (excelPayload) {
                luckysheet.setCellValue(2, 1, excelPayload['customer_number']);
                luckysheet.setCellValue(3, 1, excelPayload['company_name']);
                luckysheet.setCellValue(4, 1, excelPayload['person_forename']);
                luckysheet.setCellValue(5, 1, excelPayload['person_surname']);
                luckysheet.setCellValue(6, 1, excelPayload['address']);
                luckysheet.setCellValue(7, 1, excelPayload['city']);
                luckysheet.setCellValue(8, 1, excelPayload['plz']);
                //luckysheet.refreshFormula();
              }
            }
          }
        });
      });
  }
};
</script>

<style scoped></style>
