<template>
  <div v-if="open">
    <slot :data="data" />
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'WindowPortal',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    data: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      windowRef: null
    };
  },
  watch: {
    open(newOpen) {
      if (newOpen) {
        this.openPortal();
      } else {
        this.closePortal();
      }
    }
  },
  methods: {
    openPortal() {
      this.windowRef = window.open('', '', 'width=600,height=400');
      this.windowRef.addEventListener('beforeunload', this.closePortal);
      // magic!
      this.windowRef.document.body.appendChild(this.$el);
    },
    closePortal() {
      if (this.windowRef) {
        this.windowRef.close();
        this.windowRef = null;
        this.$emit('close');
      }
    }
  },
  mounted() {
    if (this.open) {
      this.openPortal();
    }
  },
  beforeDestroy() {
    if (this.windowRef) {
      this.closePortal();
    }
  }
});
</script>

<style scoped></style>
