<template>
  <v-card class="mx-auto" tile>
    <v-subheader>E-MAIL</v-subheader>
    <v-card-text>
      <v-text-field label="Von" :value="sender" readonly></v-text-field>
      <v-text-field label="Zu" :value="recipients" readonly></v-text-field>
      <v-text-field label="Betreff" :value="subject" readonly></v-text-field>
      <v-textarea auto-grow style="max-height: 50vh; overflow: auto" readonly :value="body"></v-textarea>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MailTextViewer',
  props: ['subject', 'sender', 'recipients', 'body']
};
</script>

<style scoped></style>
