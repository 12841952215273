
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import jwtDecode from 'jwt-decode';

@Component({})
export default class LogReg extends Vue {
  loginPassword = null;
  loginUsername = null;
  loginError: any = '';

  twoFactorInputRequired = false;
  twoFactorCodeResendDisabled = false;
  twoFactorError: any = '';
  authCode = null;

  registerEmail = null;
  registerCompany = null;
  registerStreet = null;
  registerPlz = null;
  registerCity = null;
  registerForename = null;
  registerSurname = null;
  registerError: any = '';
  registerState = 'CHECK_EMAIL';

  currentTab = 1;

  rules = {
    required: (value: any) => !!value || 'Pflichtfeld',
    counter: (value: any) => value.length <= 20 || 'Max 20 characters',
    email: (value: any) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'Ungültige E-Mail Adresse';
    }
  };

  resetRegister() {
    this.registerState = 'CHECK_EMAIL';
    this.registerEmail = null;
    this.registerError = null;
  }

  resendTwoFactorCode() {
    // disable for 60 seconds
    this.twoFactorCodeResendDisabled = true;
    setTimeout(() => {
      this.twoFactorCodeResendDisabled = false;
    }, 60000);

    axios
      .post(`${BACKEND_URL}/resend-2fa`, {
        username: this.loginUsername
      })
      .then(response => {
        if (response.status === 200) {
        }
      });
  }

  checkEmail() {
    axios
      .post(`${BACKEND_URL}/check_email`, {
        email: this.registerEmail
      })
      .then(response => {
        if (response.status === 200) {
          this.registerState = response.data.result;
        }
      })
      .catch(error => {
        this.loginError = 'Email bereits registriert!';
      });
  }

  switchToLogin() {
    this.resetRegister();
    this.currentTab = 1;
  }

  register() {
    axios
      .post(`${BACKEND_URL}/register`, {
        email: this.registerEmail,
        company: this.registerCompany,
        street: this.registerStreet,
        plz: this.registerPlz,
        city: this.registerCity,
        forename: this.registerForename,
        surname: this.registerSurname
      })
      .then(response => {
        if (response.status === 200) {
          this.$router.push('/login');
        }
      })
      .catch(error => {
        this.registerError = 'Registrierung fehlgeschlagen!';
      });
  }

  login() {
    this.loginError = null;
    this.twoFactorError = null;
    axios
      .post(`${BACKEND_URL}/login`, {
        username: this.loginUsername,
        password: this.loginPassword,
        auth_code: this.authCode
      })
      .then(response => {
        if (response.status === 200) {
          const user = jwtDecode(this.$cookies.get('jwt_header_payload'));
          this.$store.commit('setUser', user);
          this.$store.dispatch('loadPendingOffer');
          this.$router.push('/');
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          if (error.response.data.status === '2FA_REQUIRED') {
            this.twoFactorInputRequired = true;
          } else if (error.response.data.status === '2FA_FAILED') {
            this.twoFactorError = '2FA fehlgeschlagen! Code überprüfen.';
          }
          this.loginError = 'Login fehlgeschlagen! Bitte Username und Passwort überprüfen.';
        }
      });
  }

  get registerStepperStep() {
    return this.registerState === 'CHECK_EMAIL' ? 1 : 2;
  }

  get validRegisterEmail() {
    return this.rules.email(this.registerEmail) === true;
  }
}
