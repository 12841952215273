<template>
  <v-card>
    <v-subheader>
      <div>KUNDENANGABEN</div>
      <div class="d-flex justify-end flex-fill">
        <v-btn icon @click="findCustomerByEmail()">
          <v-icon>refresh</v-icon>
        </v-btn>
      </div>
    </v-subheader>
    <v-card-text>
      <v-radio-group v-model="searchCategory" row>
        <v-radio v-for="(value, key) in categories" :key="key" :label="value.label" :value="key"></v-radio>
      </v-radio-group>

      <v-autocomplete
        v-model="select"
        :loading="loading"
        :items="customers"
        :search-input.sync="search"
        cache-items
        hide-details
        hide-no-data
        hide-selected
        :item-text="extractText"
        :item-value="extractValue"
        :label="categories[searchCategory].display"
        solo-inverted
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <strong>
              <v-list-item-title v-text="item.customer_number"></v-list-item-title>
            </strong>
            <v-list-item-title v-text="item.name_1"></v-list-item-title>
            <v-list-item-subtitle v-text="displayName(item)"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <div>
        <v-text-field
          label="Kunden-Nummer"
          :value="customer.customer_number"
          @blur="changeValue('customer_number', $event.target.value)"
        ></v-text-field>
        <v-text-field
          label="Firmen-Namen"
          :value="customer.company_name"
          @blur="changeValue('company_name', $event.target.value)"
        ></v-text-field>
        <v-text-field
          label="Vorname"
          :value="customer.person_forename"
          @blur="changeValue('person_forename', $event.target.value)"
        ></v-text-field>
        <v-text-field
          label="Nachnamen"
          :value="customer.person_surname"
          @blur="changeValue('person_surname', $event.target.value)"
        ></v-text-field>
        <v-text-field
          label="Adresse"
          :value="customer.address"
          @blur="changeValue('address', $event.target.value)"
        ></v-text-field>
        <v-text-field
          label="Stadt"
          :value="customer.city"
          @blur="changeValue('city', $event.target.value)"
        ></v-text-field>
        <v-text-field label="PLZ" :value="customer.plz" @blur="changeValue('plz', $event.target.value)"></v-text-field>
        <v-text-field
          label="Telefon"
          :value="customer.phone"
          @blur="changeValue('phone', $event.target.value)"
        ></v-text-field>
        <v-text-field
          label="Telefax"
          :value="customer.telefax"
          @blur="changeValue('telefax', $event.target.value)"
        ></v-text-field>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';

export default {
  name: 'MailCustomerSearch',
  props: ['sender'],
  data() {
    return {
      customer: {
        customer_number: null,
        company_name: null,
        person_forename: null,
        person_surname: null,
        address: null,
        plz: null,
        city: null,
        phone: null,
        telefax: null
      },
      loading: false,
      search: null,
      select: null,
      customers: [],
      categories: {
        customer_nr: {
          label: 'Kunden-Nummer',
          display: 'Nach Kunden-Nummer suchen'
        },
        company_name: {
          label: 'Firmen-Namen',
          display: 'Nach Firmen-Namen suchen'
        },
        person_name: {
          label: 'Personen-Namen',
          display: 'Nach Personen-Namen suchen'
        }
      },
      searchCategory: 'customer_nr'
    };
  },
  watch: {
    offerContact(val) {
      if (val) {
        this.customer.customer_number = val.customer_number;
        this.customer.company_name = val.company_name;
        this.customer.person_forename = val.person_forename;
        this.customer.person_surname = val.person_surname;
        this.customer.address = val.address;
        this.customer.plz = val.plz_city.substr(0, val.plz_city.indexOf(' '));
        this.customer.city = val.plz_city.substr(val.plz_city.indexOf(' ') + 1);
        this.customer.phone = val.phone;
        this.customer.telefax = val.telefax;
      }
    },
    search(val) {
      val && val.length > 2 && val !== this.select && this.querySelectionDebounced(val);
    },
    searchCategory(val) {
      this.search = null;
      this.select = null;
      this.items = [];
    },
    select(val) {
      if (val) {
        this.$set(this.customer, 'customer_number', val.customer_number);
        this.customer.customer_number = val.customer_number;
        this.customer.customer_number = val.customer_number;
        this.customer.company_name = val.name_1;
        this.customer.person_forename = val.forename;
        this.customer.person_surname = val.surname;
        this.customer.address = val.street;
        this.customer.plz = val.plz;
        this.customer.city = val.city;
        this.customer.phone = val.phone;
        this.customer.telefax = val.telefax;
      }
    },
    customer: {
      handler: function(after, before) {
        this.$emit('selectedCustomer', after);
      },
      deep: true
    }
  },
  mounted() {
    const emailContact = this.$store.getters.emailContact;
    if (!emailContact || Object.keys(emailContact).length === 0) {
      this.findCustomerByEmail();
    } else {
      this.customer = { ...emailContact };
    }
  },
  methods: {
    changeValue(key, value) {
      this.$set(this.customer, key, value);
    },
    findCustomerByEmail() {
      let emailString = this.sender.replace('<', '').replace('>', '');
      let emailCandidates = emailString.split(' ').filter(candidate => candidate.includes('@'));
      if (emailCandidates.length > 0) {
        let candidate = emailCandidates[0];
        axios
          .post(`${BACKEND_URL}/search_customer`, {
            search_category: 'email',
            search_str: candidate
          })
          .then(response => {
            if (response.status === 200) {
              let result = response.data.customers;
              if (result.length > 0) {
                this.select = result[0];
              }
            }
          })
          .catch(error => {});
      }
    },
    extractText(v) {
      switch (this.searchCategory) {
        case 'customer_nr':
          return v.customer_number;
        case 'company_name':
          return v.name_1;
        case 'person_name':
          return v.forename + ' ' + v.surname;
      }
    },
    extractValue(v) {
      return v;
    },
    displayName(item) {
      let forename = item.forename ? item.forename : '';
      let surname = item.surname ? item.surname : '';
      return forename + ' ' + surname;
    },
    querySelectionDebounced(v) {
      this.loading = true;
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.querySelections(v);
      }, 500);
    },
    querySelections(v) {
      axios
        .post(`${BACKEND_URL}/search_customer`, {
          search_category: this.searchCategory,
          search_str: v
        })
        .then(response => {
          if (response.status === 200) {
            this.customers = response.data.customers;
            this.loading = false;
          }
        })
        .catch(error => {});
    }
  }
};
</script>

<style scoped></style>
