<template>
  <v-card class="mx-auto" tile>
    <v-subheader>ANHÄNGE ({{ attachments.length }})</v-subheader>
    <v-list flat style="height: 150px; overflow-y: auto">
      <v-list-item-group v-model="selectedItem" color="primary" v-if="attachments.length > 0">
        <v-list-item v-for="(item, i) in attachments" :key="i" @click="open(item)">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.filename"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click.stop="download(item)">
              <v-icon color="grey lighten-1">download</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
      <v-list-item v-else>
        <v-list-item-content>
          <v-list-item-title>Keine Anhänge</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <window-portal :open="openViewerComponent" :data="selectedItemValue">
      <STLViewerSingle :data="selectedItemValue"></STLViewerSingle>
    </window-portal>
  </v-card>
</template>

<script>
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import WindowPortal from '@/components/util/WindowPortal';
import STLViewerSingle from '@/components/util/STLViewerSingle';

export default {
  name: 'MailAttachmentViewer',
  components: { WindowPortal, STLViewerSingle },
  props: ['attachments', 'emailId'],
  data() {
    return {
      selectedItem: null,
      selectedItemValue: null,
      openViewerComponent: false
    };
  },
  methods: {
    open(item) {
      const supportedFileTypes = [
        '.jpg',
        '.jpeg',
        '.png',
        '.gif',
        '.svg',
        '.webp',
        '.mp4',
        '.webm',
        '.ogg',
        '.mp3',
        '.wav',
        '.pdf',
        '.html',
        '.txt',
        '.csv'
      ];
      if (supportedFileTypes.includes(item.ext.toLowerCase())) {
        // Supported file types: show in an iframe directly
        axios
          .post(
            `${BACKEND_URL}/attachment`,
            {
              offer_id: this.$store.getters.currentOfferID,
              file_uuid: item.uuid,
              mail_id: this.emailId
            },
            { responseType: 'blob' }
          )
          .then(response => {
            this.$parent.$refs.iframe.data = window.URL.createObjectURL(
              new Blob([response.data], { type: response.headers['content-type'] })
            );
          });
      } else {
        // Not supported file types: download the file
        this.download(item);
        // Optional: Previously commented out code
        // this.selectedItemValue = item.uuid;
        // this.openViewerComponent = true;
      }
    },
    download(item) {
      axios
        .post(
          `${BACKEND_URL}/attachment`,
          {
            offer_id: this.$store.getters.currentOfferID,
            file_uuid: item.uuid,
            mail_id: this.emailId
          },
          { responseType: 'blob' }
        )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('target', '_blank');
          link.setAttribute('download', item.filename);
          link.click();
        });
    }
  }
};
</script>

<style scoped></style>
