<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="mx" style="position:relative; height: 250px">
        <div style="position:absolute; top:0; bottom: 0; width:90%;">
          <div class="fillParent" ref="render"></div>
        </div>
        <!--<span style="height:100px" ref="render"></span>-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { BACKEND_URL } from '@/za_conf';

export default {
  name: 'STLViewerSingle',
  props: ['data'],
  data() {
    return {
      sceneElements: {},
      canvas: document.createElement('canvas'),
      renderer: new THREE.WebGLRenderer({
        canvas: this.canvas,
        alpha: true,
        antialias: true
      }),
      animationFrameId: 0,
      gltfLoader: new GLTFLoader()
    };
  },
  methods: {
    makeScene(elem) {
      const fov = 50;
      const aspect = 1; // the canvas default
      const near = 0.1;
      const far = 20;

      const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);

      const controls = new OrbitControls(camera, elem);
      controls.update();
      controls.enablePan = false;
      controls.autoRotate = true;
      controls.autoRotateSpeed = 1;
      controls.rotateSpeed = 0.6;

      const scene = new THREE.Scene();
      scene.background = new THREE.Color('white');

      {
        const color = 0xffffff;
        const intensity = 1;
        const light = new THREE.DirectionalLight(color, intensity);
        const light2 = new THREE.DirectionalLight(color, intensity);
        const light3 = new THREE.DirectionalLight(color, intensity);
        const light4 = new THREE.DirectionalLight(color, intensity);
        light.position.set(-1, 2, 4);
        light2.position.set(-1, 2, -4);
        light3.position.set(5, 0, 4);
        //light4.position.set(-1, -2, 4);
        scene.add(light);
        scene.add(light2);
        scene.add(light3);
        // scene.add(light4);
      }
      return { scene, camera, controls };
    },
    addScene(elem, fn, name) {
      const ctx = document.createElement('canvas').getContext('2d');
      if (ctx !== null) {
        elem.appendChild(ctx.canvas);
      }
      this.sceneElements[name] = { elem, ctx, fn };
    },
    frameArea(sizeToFitOnScreen, boxSize, boxCenter, camera) {
      const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.5;
      const halfFovY = THREE.MathUtils.degToRad(camera.fov * 0.5);
      const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);
      // compute a unit vector that points in the direction the camera is now
      // in the xz plane from the center of the box
      boxCenter = boxCenter.add(new THREE.Vector3(0.00001, 0.000001, 0.000001));
      const direction = new THREE.Vector3()
        .subVectors(camera.position, boxCenter)
        .multiply(new THREE.Vector3(1, 0, 1))
        .normalize();

      // move the camera to a position distance units way from the center
      // in whatever direction the camera was from the center already
      camera.position.copy(direction.multiplyScalar(distance).add(boxCenter));

      // pick some near and far values for the frustum that
      // will contain the box.
      camera.near = boxSize / 100;
      camera.far = boxSize * 100;

      camera.updateProjectionMatrix();

      // point the camera to look at the center of the box
      camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z);
    },
    createSceneInitFunction(partUUID) {
      let outer = this;
      let sceneInitFunction = elem => {
        const { scene, camera, controls } = this.makeScene(elem);
        let mesh;
        this.gltfLoader.setWithCredentials(true);
        this.gltfLoader.load(`${BACKEND_URL}/gltf_mail/${partUUID}`, function(gltf) {
          const root = gltf.scene;
          scene.add(root);
          // compute the box that contains all the stuff
          // from root and below
          const box = new THREE.Box3().setFromObject(root);

          const boxSize = box.getSize(new THREE.Vector3()).length();
          const boxCenter = box.getCenter(new THREE.Vector3());

          // set the camera to frame the box
          outer.frameArea(boxSize * 1.1, boxSize, boxCenter, camera);

          // update the Trackball controls to handle the new size
          controls.maxDistance = boxSize * 10;
          controls.target.copy(boxCenter);
          controls.update();
        });
        return (rect, key, ctx, width, height) => {
          camera.aspect = rect.width / rect.height;
          camera.updateProjectionMatrix();
          controls.update();
          this.renderer.render(scene, camera);
          ctx.globalCompositeOperation = 'copy';
          ctx.drawImage(
            this.renderer.domElement,
            0,
            this.renderer.domElement.height - height,
            width,
            height, // src rect
            0,
            0,
            width,
            height
          ); // dst rect
        };
      };
      return sceneInitFunction;
    },
    renderfn() {
      let count = 0;
      for (const key of Object.keys(this.sceneElements)) {
        const { elem, fn, ctx } = this.sceneElements[key];
        // get the viewport relative position opf this element
        const rect = elem.getBoundingClientRect();
        const { left, right, top, bottom, width, height } = rect;
        const rendererCanvas = this.renderer.domElement;
        const isOffscreen =
          elem.offsetParent === null || bottom < 0 || top > window.innerHeight || right < 0 || left > window.innerWidth;
        if (!isOffscreen) {
          count += 1;
          // make sure the renderer's canvas is big enough
          if (rendererCanvas.width < width || rendererCanvas.height < height) {
            this.renderer.setSize(width, height, false);
          }
          // make sure the canvas for this area is the same size as the area
          if (ctx.canvas.width !== width || ctx.canvas.height !== height) {
            ctx.canvas.width = width;
            ctx.canvas.height = height;
          }
          this.renderer.setScissor(0, 0, width, height);
          this.renderer.setViewport(0, 0, width, height);
          fn(rect, key, ctx, width, height);
          // copy the rendered scene to this element's canvas
          // TODO i have removed copy, maybe redo?
        }
      }
      this.animationFrameId = requestAnimationFrame(this.renderfn);
    }
  },
  mounted() {
    cancelAnimationFrame(this.animationFrameId);
    const sceneInitFunction = this.createSceneInitFunction(this.data);
    let elem = this.$refs.render;
    const sceneRenderFunction = sceneInitFunction(elem);
    this.addScene(elem, sceneRenderFunction, this.data);
    this.animationFrameId = requestAnimationFrame(this.renderfn);
    this.renderer.setScissorTest(true);
    this.gltfLoader.setWithCredentials(true);
  }
};
</script>

<style scoped></style>
